import {
	directories,
  directoriesTransportCompany,
	regions,
	locations,
	settlements,
	users,
	transportCatalog
} from '@/configs/endpoints'
import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { i18n } from '@/plugins/vue-i18n.js'
const store = 'directory'

export default {
	state: () => ({
		regions: {},
		settlementsRegions: {},
		settlements: {},
		postalCodes: {},
		responsible: {},
		counters: {},
		models: {},
		marks: {},
		carTypes: {},
		protocolStatuses: {},
		departments: {},
		locations: {},
		positions: [
			{
				item: 'Начальник',
				name: i18n.t('positions.head')
			},
			{
				item: 'Виконуючий обовʼязки начальника',
				name: i18n.t('positions.deputy')
			},
			{
				item: 'Експерт',
				name: i18n.t('positions.expert')
			}
		],
		measurementModes: [
			{
				id: 1,
				name: 'dynamic'
			},
			{
				id: 2,
				name: 'static'
			}
		]
	}),
	actions: {
		getDirectories({ commit, rootState }) {
			for (const directory of rootState.profile.profile.type_user === 'Transport company'
				? directoriesTransportCompany
				: directories) {
				api.get(directory.endpoint).then(response => {
					if (requestValidation(response))
						commit('SET_DATA', {
							store,
							state: directory.state,
							data: response.data
						})
				})
			}
		},
		async getDirectoryByParams (context, data) {
			const response = await api.get(`${directory[data.endpoint](data.id)}`)

			if (await requestValidation(response)) {
				const result =
					Array.isArray(response.data) &&
					response.data.filter(i => i.is_active || i.is_active === undefined)
				context.commit('SET_DATA', {
					store,
					state: data.endpoint,
					data: result
				})
			}
		},
		async getDirectoryById (context, data) {
			const response = await api.get(`${directory[data.endpoint](data.id)}`)

			if (await requestValidation(response)) {
				context.commit('ADD_DATA', {
					store,
					state: data.state || data.endpoint,
					data: response.data
				})
				return response.data
			}
		},
		async getResponsible (context, param) {
			const params = new URLSearchParams()

			typeof param === 'object'
				? params.append('ids', param)
				: params.append('full_name', param)
			params.append('page_size', 5)

			const response = await api.get(`${users.users}?${params}`)

			if (await requestValidation(response))
				context.commit('ADD_DATA_WITH_AVAILABILITY', {
					store,
					state: 'responsible',
					data: response.data
				})

			return response.data.code
		},

		async getRegionsDirectory (context, param) {
			const params = new URLSearchParams()

			typeof param === 'object'
				? params.append('ids', param)
				: params.append('value', param)
			params.append('page_size', 5)

			const response = await api.get(`${regions.regions}?${params}`)

			if (await requestValidation(response))
				context.commit('ADD_DATA_WITH_AVAILABILITY', {
					store,
					state: 'regions',
					data: response.data
				})

			return response.data.code
		},

		async getLocationsDirectory (context, param) {
			const params = new URLSearchParams()

			typeof param === 'object'
				? params.append('ids', param)
				: params.append('value', param)
			params.append('page_size', 5)

			const response = await api.get(`${locations.locations}?${params}`)

			if (await requestValidation(response))
				context.commit('ADD_DATA_WITH_AVAILABILITY', {
					store,
					state: 'locations',
					data: response.data
				})

			return response.data.code
		},

		async getTransportMarksDirectory ({ commit }, param) {
			const params = new URLSearchParams()

			typeof param === 'object'
				? params.append('ids', param)
				: params.append('name', param)
			params.append('page_size', 5)

			const response = await api.get(`${transportCatalog.marks}?${params}`)

			if (await requestValidation(response))
				commit('ADD_DATA_WITH_AVAILABILITY', {
					store,
					state: 'marks',
					data: response.data
				})

			return response.data.code
		},

		async getCarTypes ({ commit }, param) {
			const params = new URLSearchParams()

			typeof param === 'object'
				? params.append('ids', param)
				: params.append('name', param)
			params.append('page_size', 5)

			const response = await api.get(`${transportCatalog.carTypes}?${params}`)

			if (await requestValidation(response))
				commit('ADD_DATA_WITH_AVAILABILITY', {
					store,
					state: 'carTypes',
					data: response.data
				})

			return response.data.code
		},

		async setTransportModelsDirectory ({ commit }, param) {
			const params = new URLSearchParams()

			param.length && params.append('marks', param)

			const response = await api.get(`${transportCatalog.models}?${params}`)

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'models', data: response.data })

			return response.data.code
		},

		async getTransportModelsDirectory ({ commit }, param) {
			const params = new URLSearchParams()

			typeof param === 'object'
				? params.append('marks', param)
				: params.append('search', param)
			params.append('page_size', 5)

			const response = await api.get(`${transportCatalog.models}?${params}`)

			if (await requestValidation(response))
				commit('ADD_DATA_WITH_AVAILABILITY', {
					store,
					state: 'models',
					data: response.data
				})

			return response.data.code
		},

		async getSettlementsRegionsDirectory (context, param) {
			const params = new URLSearchParams()

			typeof param === 'object'
				? params.append('ids', param)
				: params.append('name', param)
			params.append('page_size', 5)

			const response = await api.get(`${settlements.regions}?${params}`)

			if (await requestValidation(response))
				context.commit('ADD_DATA_WITH_AVAILABILITY', {
					store,
					state: 'settlements',
					data: response.data
				})

			return response.data.code
		},

		async getSettlementsDirectory (context, param) {
			const params = new URLSearchParams()

			typeof param !== 'object' && params.append('state_region', param)

			const response = await api.get(`${settlements.settlements}?${params}`)

			if (await requestValidation(response))
				context.commit('SET_DATA', {
					store,
					state: 'settlements',
					data: response.data
				})

			return response.data.code
		},

		async getPostalCodesDirectory (context, param) {
			const params = new URLSearchParams()

			typeof param !== 'object' && params.append('state_region', param)

			const response = await api.get(`${settlements.postalCodes}?${params}`)

			if (await requestValidation(response))
				context.commit('SET_DATA', {
					store,
					state: 'postalCodes',
					data: response.data
				})

			return response.data.code
		}
	},
	getters: {
		getByID: state => (from, id) => state[from].find(mode => mode.id === id)
	}
}
