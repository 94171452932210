import Vue from 'vue'
import VueRouter from 'vue-router'

import SignIn from '@/views/SignIn'

import store from '@/store'

import { access } from '@/configs/permissions'

Vue.use(VueRouter)

const measurements = [
	{
		path: '/measurements',
		name: 'menu.measurements',
		component: () => import('@/views/Measurements'),
		meta: {
			groupName: 'menu.main',
			access: 'measurementsView'
		}
	},
	{
		path: '/measurements/:id',
		name: 'measurement',
		meta: {
			groupName: 'menu.main',
			access: 'measurementsView'
		},
		component: () => import('@/views/Measurements/SubPage')
	}
]

const devices = [
	{
		path: '/devices',
		name: 'menu.devices',
		component: () => import('@/views/Devices'),
		meta: {
			access: 'devicesView',
			groupName: 'menu.main'
		}
	},
	{
		path: '/devices/:id',
		name: 'device',
		component: () => import('@/views/Devices/SubPage'),
		meta: {
			access: 'devicesView',
			groupName: 'menu.main'
		}
	}
]

const protocols = [
	{
		path: '/protocols',
		name: 'menu.protocols',
		component: () => import('@/views/Protocols'),
		meta: {
			access: 'public',
			groupName: 'menu.main'
		}
	},
	{
		path: '/protocols/:id',
		name: 'protocol',
		component: () => import('@/views/Protocols/SubPage'),
		meta: {
			access: 'public',
			groupName: 'menu.main'
		}
	}
]

const basicRoutes = [
	{
		path: '/',
		name: 'Main',
		component: () => import('@/components/Layouts/Main'),
		meta: {
			access: 'public'
		},
		children: [
			{
				path: 'dashboard',
				name: 'menu.main',
				component: () => import('@/views/Dashboard'),
				meta: {
					access: 'dashboardView'
				}
			},
			{
				path: 'profile',
				name: 'menu.profile',
				component: () => import('@/views/Profile'),
				meta: {
					access: 'public',
					groupName: 'menu.main'
				}
			},
			{
				path: 'workers',
				name: 'menu.workers',
				component: () => import('@/views/Workers'),
				meta: {
					access: 'usersView',
					groupName: 'menu.main'
				}
			},
			{
				path: 'regions',
				name: 'menu.regions',
				component: () => import('@/views/Regions'),
				meta: {
					access: 'canViewRegion',
					groupName: 'menu.main'
				}
			},
			{
				path: 'locations',
				name: 'menu.locations',
				component: () => import('@/views/Locations'),
				meta: {
					access: 'canViewLocation',
					groupName: 'menu.main'
				}
			},
			{
				path: 'transport-companies',
				name: 'menu.transportCompanies',
				component: () => import('@/views/TransportCompanies'),
				meta: {
					access: 'public',
					groupName: 'menu.main'
				}
			},
			{
				path: 'transport-catalog',
				name: 'menu.transportCatalog',
				component: () => import('@/views/TransportCatalog'),
				meta: {
					access: 'canViewTransportCatalog',
					groupName: 'menu.main'
				}
			},
			...measurements,
			...devices,
			...protocols
		]
	},
	{
		path: '/404',
		name: 'no-found',
		meta: {
			public: true,
			access: 'public'
		},
		component: () => import('@/views/404')
	},
	{
		path: '/502',
		name: 'bad-gatewey',
		meta: {
			public: true,
			access: 'public'
		},
		component: () => import('@/views/502')
	},
	{
		path: '/confirm-password',
		name: 'ConfirmPassword',
		component: () => import('@/views/ConfirmPassword'),
		meta: {
			access: 'public',
			public: true
		}
	}
]

const authorization = [
	{
		path: '/login',
		name: 'Authentication',
		component: SignIn,
		meta: {
			access: 'public'
		}
	}
]

let routes = [...basicRoutes, ...authorization, ...measurements]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL || '/',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) return savedPosition
		return { left: 0, top: 0 }
	},
	routes
})

router.beforeEach((to, from, next) => {
	if (
		from.name === to.name &&
		JSON.stringify(from.query) !== JSON.stringify(to.query)
	) {
		next()
	} else {
		const isAuth = Vue.prototype.$auth.isAuth()
		store.commit('SET_LOAD', false)

		if (isAuth) {
			store
				.dispatch('getProfile')
				.then(() => {
					if (
						to.name === 'no-found' ||
						to.name === 'server-error' ||
						to.name === 'ConfirmPassword'
					)
						next()
					else if (to.meta.public) next('/')
					else access(to.meta.access) ? next() : next('/404')
				})
				.finally(() => {
					store.commit('SET_LOAD', true)
				})
		} else {
			store.commit('SET_LOAD', true)
			if (['Authentication', 'ConfirmPassword'].includes(to.name)) next()
			else {
				const oldPath = encodeURIComponent(to.fullPath)
				next({ path: '/login', query: { path: oldPath } })
			}
		}
	}
})

export default router
