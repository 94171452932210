import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { devices as endpoint } from '@/configs/endpoints'
import notify from '@ananasbear/notify'

const store = 'devices'

export default {
	state: () => ({
		devices: {},
		device: {},
		deviceHistory: {},
		isLoad: false
	}),
	actions: {
		async getDevices (context, filter) {
			context.commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()
			if (filter) {
				for (const [key, value] of Object.entries(filter))
					value && params.append(key, value)
			}

			const response = await api.get(`${endpoint.devices}?${params}`)
			response.data.results = response.data.results.map(item => ({
				...item,
				device_status: !item.is_active ? 3 : item.status
			}))

			if (await requestValidation(response))
				context.commit('SET_DATA', {
					store,
					state: 'devices',
					data: response.data
				})

			context.commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getDevice (context, id) {
			context.commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(endpoint.device(id))

			if (await requestValidation(response))
				context.commit('SET_DATA', {
					store,
					state: 'device',
					data: response.data
				})

			context.commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async createDevice (context, data) {
			const response = await api.post(endpoint.devices, { body: data })

			if (await requestValidation(response)) {
				context.commit('ADD_DATA_TO_RESULTS', {
					store,
					state: 'devices',
					data: response.data
				})
				notify.success('notify.deviceIsCreated')
			}

			return response.code
		},

		async editDevice (context, data) {
			const response = await api.patch(endpoint.device(data.id), {
				body: data.body
			})

			if (await requestValidation(response)) {
				context.commit('SET_DATA', {
					store,
					state: 'device',
					data: response.data
				})
				notify.success('notify.deviceIsEdit')
			}

			return response.code
		},

		async getDevicesHistory (context, filter) {
			const params = new URLSearchParams()
			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(
				`${endpoint.deviceHistory(context.state.device.id)}?${params}`
			)

			if (await requestValidation(response))
				context.commit('SET_DATA', {
					store,
					state: 'deviceHistory',
					data: response.data
				})

			return response.code
		},

		async getDevicesStats (context, id) {
			const response = await api.get(endpoint.deviceStats(id))

			if (await requestValidation(response)) {
				return response.data
			}
		}
	},
	getters: {}
}
