import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { transportCompanies as endpoint } from '@/configs/endpoints'
import notify from '@ananasbear/notify'
const store = 'transportCompanies'

export default {
  state: () => ({
    transportCompanies: {},
    transportCompany: {},
    isLoad: false
  }),
  actions: {
    async getTransportCompanies ({ commit }, filter) {
      commit('SET_DATA', { store, state: 'isLoad', data: true })

      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) value && params.append(key, value)

      const response = await api.get(`${endpoint.transportCompanies}?${ params }`)

      if (await requestValidation(response)) commit('SET_DATA', { store, state: 'transportCompanies', data: response.data })

      commit('SET_DATA', { store, state: 'isLoad', data: false })

      return response.code
    },

    async getTransportCompany ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(endpoint.transportCompany(id))

			if (await requestValidation(response))
				commit('SET_DATA', {
					store,
					state: 'transportCompany',
					data: response.data
				})

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

    async createTransportCompany ({ commit }, data) {
      const response = await api.post(endpoint.transportCompanies, { body: data })

      if (await requestValidation(response)) {
        commit('ADD_DATA_TO_RESULTS', { store, state: 'transportCompanies', data: response.data })
        notify.success('notify.transportCompanyIsCreated')
      }

      return response
    },

    async editTransportCompany ({ commit }, data) {
      const response = await api.patch(endpoint.transportCompany(data.id), { body: data })

      if (await requestValidation(response)) {
        commit('UPDATE_DATA_TO_RESULTS', { store, state: 'transportCompanies', data: response.data })
        notify.success('notify.transportCompanyIsEdited')
      }

      return response.code
    },
    
    async editAccTransportCompany ({ commit }, data) {
      const response = await api.patch(`${endpoint.transportCompany(data.id)}?edrpou=${data.edrpou}`, { body: data })

      if (await requestValidation(response)) {
        commit('UPDATE_DATA_TO_RESULTS', { store, state: 'transportCompanies', data: response.data })
        notify.success('notify.transportCompanyIsEdited')
      }

      return response.code
    },

    async removeTransportCompany ({ commit }, id) {
      const response = await api.delete(endpoint.transportCompany(id))
     
      if (await requestValidation(response)) {
        commit('UPDATE_DATA_TO_RESULTS', { store, state: 'transportCompanies', data: response.data })
        notify.success('notify.transportCompanyIsRemoved')
      }

      return response.code
    }
  },
  getters: {}
}
