import { i18n } from '@/plugins/vue-i18n.js'
import '@/assets/sass/notifications.sass'

import InfoIcon from '@/assets/icons/notification-info.svg'
import SuccessIcon from '@/assets/icons/notification-success.svg'
import WarningIcon from '@/assets/icons/notification-warning.svg'
import ErrorIcon from '@/assets/icons/notification-error.svg'

export default {
	global: {
		close: true,
		pauseOnHover: true,
		timeout: 3000,
		progressBar: true,
		layout: 2,
		position: 'topRight'
	},

	info: {
		iconUrl: InfoIcon,
		title: `${i18n.t('notify.info')}!`
	},

	success: {
		iconUrl: SuccessIcon,
		title: `${i18n.t('notify.success')}!`
	},

	warning: {
		iconUrl: WarningIcon,
		title: `${i18n.t('notify.warning')}!`
	},

	error: {
		iconUrl: ErrorIcon,
		title: `${i18n.t('notify.error')}!`
	}
}
