<template lang="pug">
v-app
  router-view
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
		...mapState({
			profile: state => state.profile.profile
		})
	},
	updated () {
		if (
			this.profile.should_change_password &&
			this.$router.currentRoute.path != '/profile'
		)
			this.$router.push('/profile')
	}
}
</script>
