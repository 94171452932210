export default {
	// Vuetify components translations
	$vuetify: {
		badge: 'Значок',
		close: 'Закрити',
		dataIterator: {
			noResultsText: 'Відповідних записів не знайдено',
			loadingText: 'Завантаження елементів...'
		},
		dataTable: {
			itemsPerPageText: 'Рядки на сторінці:',
			ariaLabel: {
				sortDescending: 'Відсортовано за спаданням.',
				sortAscending: 'Відсортовано за зростанням.',
				sortNone: 'Не відсортовані.',
				activateNone: 'Активуйте, щоб прибрати сортування.',
				activateDescending: 'Активуйте, щоб сортувати за спаданням.',
				activateAscending: 'Активуйте, щоб сортувати за зростанням.'
			},
			sortBy: 'Сортувати за'
		},
		dataFooter: {
			itemsPerPageText: 'Елементів на сторінці:',
			itemsPerPageAll: 'Усі',
			nextPage: 'Наступна сторінка',
			prevPage: 'Попередня сторінка',
			firstPage: 'Перша сторінка',
			lastPage: 'Остання сторінка',
			pageText: '{0}-{1} of {2}'
		},
		datePicker: {
			itemsSelected: '{0} вибрано',
			nextMonthAriaLabel: 'Наступний місяць',
			nextYearAriaLabel: 'Наступний рік',
			prevMonthAriaLabel: 'Попередній місяць',
			prevYearAriaLabel: 'Попередній рік',
			dateStart: 'Дата початку',
			dateEnd: 'Дата кінця'
		},
		noDataText: 'Дані відсутні',
		carousel: {
			prev: 'Previous visual',
			next: 'Next visual',
			ariaLabel: {
				delimiter: 'Carousel slide {0} of {1}'
			}
		},
		calendar: {
			moreEvents: '{0} більше'
		},
		fileInput: {
			counter: '{0} файли',
			counterSize: '{0} файлів ({1} загалом)'
		},
		timePicker: {
			am: 'AM',
			pm: 'PM'
		},
		pagination: {
			ariaLabel: {
				wrapper: 'Навігація пагінацією',
				next: 'Наступна сторінка',
				previous: 'Попередня сторінка',
				page: 'Перейти на сторінку {0}',
				currentPage: 'Поточна сторінка, Сторінка {0}'
			}
		}
	}
}
