<template lang="pug">
include ../../plugins/pug/template

v-main.auth-pages
  .header-auth.position-relative.border-radius-xl.min-vh-100.align-center.d-flex
    v-container
      v-row
        v-col(
          cols="6").d-lg-flex.d-none.h-100.my-auto.pe-0.pa-0.position-absolute.top-0.start-0.text-center.justify-center.flex-column
          .position-relative.h-100.border-radius-lg.d-flex.flex-column.justify-content-center(
            :style="`background-image: url(${require('@/assets/img/login-img.jpg')}); background-size: cover;`")
        v-col(
          lg="4"
          md="7").d-flex.flex-column.ms-lg-auto.me-lg-5.py-0
          component(:is="componentName" :component-name.sync="componentName")
</template>

<script>
export default {
	// eslint-disable-next-line vue/match-component-file-name
	name: 'SignIn',
	components: {
    Sign: () => import('@/views/SignIn/Forms/Sign'),
    ResetPassword: () => import('@/views/SignIn/Forms/ResetPassword'),
    RegisterTransportCompany: () => import('@/views/SignIn/Forms/RegisterTransportCompany')
	},
	data () {
		return {
      componentName: 'Sign'
		}
	}
}
</script>
