export default {
	btn: {
		ok: 'OK',
		add: 'Додати',
		remove: 'Видалити',
		change: 'Змінити',
		open: 'Відкрити',
		back: 'Назад',
		sign: 'Увійти',
		approve: 'Схвалити',
		reject: 'Відхилити',
		rejectStatement: 'Відхилити заявку',
		save: 'Зберегти',
		detail: 'Деталі',
		moreDetails: 'Детальніше',
		edit: 'Редагувати',
		deactivate: 'Деактивація',
		saveAndNext: 'Зберегти та продовжити',
		clear: 'Очистити',
		clearForm: 'Очистити форму',
		next: 'Далі',
		previous: 'Попередня',
		cancel: 'Скасувати',
		choose: 'Обрати',
		saveStatement: 'Переглянути заявку',
		start: 'Почати',
		createStatement: 'Створити заявку',
		reset: 'Скинути',
		show: 'Показати',
		hide: 'Сховати',
		closeSession: 'Завершити сеанс',
		confirm: 'Підтвердити',
		printPdf: 'Друк PDF',
		previousStep: 'Повернутись назад',
		inRegister: 'В реєстр',
		toMeasurements: 'До вимірювань',
		addRegion: 'Додати регіональний центр',
		addWorker: 'Додати співробітника',
		addTransportCompany: 'Додати транспортну компанію',
		send: 'Відправити',
		clearFilter: 'Очистити фільтри',
		apply: 'Застосувати',
		review: 'Переглянути',
		signDocument: 'Підписати',
		toComplete: 'Завершити',
    return: 'Повернутися',
    register: 'Зареєструвати'
	}
}
