export default {
	units: {
		year: 'рік',
		years: 'рік(ів)',
		ton: 'т',
		kg: 'кг',
		people: 'чоловік',
		pieces: 'шт',
		meter: 'м',
		cubicMeter: 'м³',
		cubicSM: 'cм³',
		knot: 'узел',
		mm: 'мм',
		speed: 'км/г',
		kw: 'кВт',
		power: 'В',
		horsepower: 'к.с',
		miles: 'Милі',
		squareMeter: 'м²',
		volt: 'В',
		mile: 'мілі',
		km: 'км',
		litre: 'л',
		megaPascal: 'мПа',
		kiloPascal: 'кПа',
		newton: 'Н',
		kilonewton: 'кН',
		rpm: 'об/хв',
		tpm: 'разів/хв',
		c: '°C',
		degree: '°',
		percent: '%',
		second: 'c',
		naturCoef: 'K, 1/m',
		linrCoef: 'N, %',
		coef: 'K',
		dB: 'дБ',
		radius: 'R',
		days: 'дні',
		minutes: 'зараз | {n} хвилина тому | {n} хвилини тому | {n} хвилин тому'
	}
}
