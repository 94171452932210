<template lang="pug">
include ../../plugins/pug/template

div
  .tableFilterLoader
    transition(name="fade")
      v-progress-linear(
        v-if="isLoad"
        indeterminate
        color="green")
  v-card-text.px-0.py-0
    v-data-table(
      :headers="headers"
      :items="items"
      :search="search"
      :page.sync="page"
      hide-default-footer
      :items-per-page="itemsPerPage"
      mobile-breakpoint="0"
      :loading-text="$t('loading')"
      :no-results-text="$t('dataNotFound')"
      :no-data-text="$t('noData')"
      @update:options="updateOptions"
      :class="{'table--clickable-row': detailedRoute}"
      :item-class="(item) => setItemClass(item)"
    ).table
      template(#item.status="{ item }")
        div {{ item.status }}

      template(#item.is_active="{ item }")
        .status(:class="!item.is_active ? 'status-grey' : 'status-green'")
          span {{ item.is_active ? $t('statuses.active') : $t('statuses.notActive') }}

      template(#item.actions="{ item }")
        .actionCell
          .actionCell-dots
            i
          .actionCell-nav
            ul
              li(v-for="(action, i) in actions" :key="i")
                router-link(v-if="action.condition === 'goToDevices'" :to="routeToDevices(item.id)") {{ $t(action.label) }}
                span(v-else @click="$emit('onPressAction', [action, item])" ) {{ $t(action.label) }}

      template(#item.modified_at="{ item }")
        div {{ $moment(item.modified_at).format("DD.MM.YYYY HH:mm:ss") }}

      template(#item.balance="{ item }")
        div {{ item.balance ?  `${item.balance}&nbsp;UAH` : '' }}

      template(#item.valid_date="{ item }")
        div {{ item.valid_date ? $moment(item.valid_date).format("DD.MM.YYYY") : '' }}

      template(#item.created_at="{ item }")
        div {{ $moment(item.created_at).format("DD.MM.YYYY HH:mm:ss") }}

      template(#item.measurement_date="{ item }")
        div {{ $moment(item.measurement_date).format("DD.MM.YYYY HH:mm:ss") }}

      template(#item.date_of_review="{ item }")
        div {{ item.date_of_review ? $moment(item.date_of_review).format("DD.MM.YYYY HH:mm:ss") : '' }}

      template(#item.history_date="{ item }")
        div {{ item.history_date ? $moment(item.history_date).format("DD.MM.YYYY HH:mm:ss") : '-' }}

      template(#item.phones="{ item }")
        div(v-for="(phone, index) in item.phones" :key="index") {{ phone }}

      template(#item.position="{ item }")
        .status(:class="`status-${item.type_user.replaceAll(' ', '').toLowerCase()}`")
          span {{ item.position }}

      template(#item.device_status="{ item }")
        .status(
          :class="!item.is_active ? 'status-grey' : item.status === 1 ? 'status-green' : 'status-yellow'")
          span {{ item.device_status === 1 ? $t('statuses.online') : item.device_status === 2 ? $t('statuses.offline') : $t('statuses.notActive') }}

      template(#item.files[0]?.files="{ item }")
        template(v-if="item.files[0]")
          .d-flex.gap-1
            img(
              v-for="file of Object.keys(item.files[0].files).length"
              :src="require('@/assets/icons/pdf.svg')"
              alt="icon-pdf"
              width="20px")
        template(v-else)
          div &nbsp;

  v-card-actions.card-padding(v-if="!hidePagination")
    v-row(v-if="!!items.length")
      v-col(cols="4").px-0
        .d-flex.align-center.table-positions
          +select({ model: 'itemsPerPage', items: 'itemsPerPageList', itemText: 'text', itemValue: 'value' })(@change="change")
          span.ml-3.table-positions__text {{ $t('showingPositions', { count: itemsPerPage > count ? count : items.length, all: count })}}

      v-col(cols="6").ml-auto.d-flex.justify-end.px-0

        v-pagination(
          v-if="Math.ceil(count / itemsPerPage) > 1"
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="#1ABD6F"
          v-model="getPage"
          :length="Math.ceil(count / itemsPerPage)"
          @input="change"
          circle
        )
</template>

<script>

export default {
	name: 'Table',
	props: {
		items: { type: Array, default: () => [] },
		headers: { type: Array, default: () => [] },
		count: { type: Number, default: 1 },
		pageProp: { type: Number, default: 1 },
		isLoad: { type: Boolean, default: false },
		title: { type: String, default: null },
		subtitle: { type: String, default: null },
		detailedRoute: { type: String, default: null },
		typePage: { type: String, default: '' },
		actions: { type: Array, default: () => [] },
		getData: { type: Function, default: () => {} },
		hidePagination: { type: Boolean, default: false }
	},
	data () {
		return {
			itemsPerPageList: [
				{ value: 20, text: this.$t('positionsCount', { count: 20 }) },
				{ value: 50, text: this.$t('positionsCount', { count: 50 }) },
				{ value: 100, text: this.$t('positionsCount', { count: 100 }) }
			],
			itemsPerPage: 20,
			search: '',
			page: this.pageProp
		}
	},
	computed: {
		getPage: {
			get () {
				return this.pageProp
			},
			set (val) {
				this.page = val
			}
		}
	},
	mounted () {
		this.setDefaultProperties()
		document.querySelector('tbody').addEventListener('click', this.clickRow)
	},
	beforeUnmount () {
		document.querySelector('tbody').addEventListener('click', this.clickRow)
	},
	methods: {
		clickRow (e) {
			const allRow = [...document.querySelector('tbody').querySelectorAll('tr')]
			const currentRow = e.target.closest('tr')
			const currentItem = this.items[allRow.indexOf(currentRow)]
			const isSelection = document.getSelection().toString()
			if (currentItem && !isSelection) this.$emit('clickRow', currentItem)
		},
		routeToDevices (id) {
			switch (this.$router.history.current.path) {
				case '/workers/':
				case '/workers':
					return `/devices/?responsible=${id}`
				case '/regions/':
				case '/regions':
					return `/devices/?region=${id}`
			}
		},
		change () {
			const query = Object.assign({}, this.$route.query)
			query.page = this.page
			query.page_size = this.itemsPerPage
			this.$router.push({ query: query }, () => {})

			this.getData()
		},
		async setDefaultProperties () {
			let params = {}
			for (const [key, value] of Object.entries(this.$route.query)) {
				params[key] = value
			}

			if (!Object.keys(params).length) {
				const query = Object.assign({}, this.$route.query)
				params.page = 1
				query.page = 1

				params.page_size = this.itemsPerPage
				query.page_size = this.itemsPerPage
				await this.$router.push({ query: query }, () => {})
			}
		},
		updateOptions ({ sortBy, sortDesc }) {
			let queryValue
			switch (sortBy[0]) {
				case 'name':
				case 'device.name':
					;['/measurements/', '/devices/'].includes(this.detailedRoute)
						? (queryValue = 'device_name')
						: (queryValue = 'name')
					break
				case 'device_status':
					queryValue = 'status'
					break
				case 'card_number':
					queryValue = 'id'
					break
				case 'device.serial_number':
					queryValue = 'serial_number'
					break
				case 'value':
        case 'short_name':
					queryValue = 'name'
					break
				case 'count_devices':
					queryValue = 'devices'
					break
				case 'full_name':
					queryValue = 'fullname'
					break
				case 'modified_at':
				case 'history_date':
					queryValue = 'date'
					break
				case 'created_at':
					queryValue = 'created'
					break
				case 'car_mark.name':
					queryValue = 'mark'
					break
				case 'car_model_name':
					queryValue = 'name'
					break
				case 'history_user.full_name':
					queryValue = 'changed_by_name'
					break
				case 'changes[0].new':
					queryValue = 'serial_number'
					break
				case 'region_data.value':
					queryValue = 'region'
					break
				case 'count_locations':
					queryValue = 'locations'
					break
				default:
					queryValue = sortBy[0]?.replace(/\..*/, '')
			}

			const query = Object.assign({}, this.$route.query)

      if (queryValue)
				String(sortDesc[0]) === 'true'
					? (query.order = queryValue)
					: (query.order = `-${queryValue}`)
			else delete query.order

			if (
				JSON.stringify(query) !==
				JSON.stringify(Object.assign({}, this.$route.query))
			) {
				this.$router.push({ query }, () => {})
				this.getData()
			}
		},
		setItemClass (item) {
			const device = /^\/devices\/\d+$/.test(this.$router.history.current.path)

			if (device) {
				this.deviceEventChanges = item.changes.filter(
					change => change.field !== 'is_active'
				)
				if (this.deviceEventChanges.length) return 'clickable'
			} else if (this.detailedRoute) return 'clickable'
		}
	}
}
</script>

<style lang="sass" scoped>
.actionCell
  position: relative
  width: 24px
  height: 24px
  float: right
  &:hover
    .actionCell-nav
      display: block
  &-dots
    display: flex
    align-items: center
    justify-content: center
    position: relative
    width: 24px
    height: 24px
    i
      display: block
      width: 4px
      height: 4px
      background: #7B809A
      border-radius: 50%
      &::after, &:before
        position: absolute
        content: ''
        width: 4px
        height: 4px
        background: #7B809A
        border-radius: 50%
      &::before
        left: 2px
      &::after
        right: 2px
    &:hover
      i
        background: #16A15E
        &::after, &:before
          background: #16A15E
  &-nav
    position: absolute
    display: none
    padding: 12px
    box-shadow: 0px 3px 6px rgba(165, 176, 189, 0.25)
    background: #fff
    top: 0
    right: 20px
    border-radius: 8px
    z-index: 2
    ul
      margin: 0
      padding: 0
      li
        list-style-type: none
        font-size: 12px
        margin-bottom: 12px
        cursor: pointer
        white-space: nowrap
        color: rgba(0, 0, 0, 0.87)!important
        &:last-of-type
          margin-bottom: 0
          color: #E91F63 !important
        a
          color: rgba(0, 0, 0, 0.87)!important
          text-decoration: none
</style>
