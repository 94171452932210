import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { transportCatalog as endpoint } from '@/configs/endpoints'
import notify from '@ananasbear/notify'
const store = 'transportCatalog'

export default {
	state: () => ({
		models: {},
		marks: {},
		trailerModels: {},
		trailerMarks: {},
		isLoad: false
	}),
	actions: {
		async getTransportModels ({ commit }, filter) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()
			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(`${endpoint.models}?${params}`)

			if (await requestValidation(response)) {
				if (filter.car_type === 2) {
					commit('SET_DATA', {
						store,
						state: 'trailerModels',
						data: response.data
					})
					commit('SET_DATA', {
						store,
						state: 'trailerMarks',
						data: response.data
					})
				} else
					commit('SET_DATA', { store, state: 'models', data: response.data })
			}

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async createTransportModel ({ commit }, data) {
			const response = await api.post(endpoint.models, { body: data })

			if (await requestValidation(response)) {
				commit('ADD_DATA_TO_RESULTS', {
					store,
					state: 'models',
					data: response.data
				})
				notify.success('notify.transportModelIsCreated')
			}

			return response.code
		},

		async editTransportModel ({ commit }, data) {
			const response = await api.patch(endpoint.model(data.id), { body: data })

			if (await requestValidation(response)) {
				commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'models',
					data: response.data
				})
				notify.success('notify.transportModelIsEdited')
			}

			return response.code
		},

		async removeTransportModel ({ commit }, id) {
			const response = await api.delete(endpoint.model(id))

			if (await requestValidation(response)) {
				commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'models',
					data: response.data
				})
				notify.success('notify.transportModelIsRemoved')
			}

			return response.code
		},

		async getTransportMarks ({ commit }, filter) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()
			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(`${endpoint.marks}?${params}`)

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'marks', data: response.data })

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async createTransportMark ({ commit }, data) {
			const response = await api.post(endpoint.marks, { body: data })

			if (await requestValidation(response)) {
				commit('ADD_DATA_TO_RESULTS', {
					store,
					state: 'marks',
					data: response.data
				})
				notify.success('notify.transportMarkIsCreated')
			}

			return response.code
		},

		async editTransportMark ({ commit }, data) {
			const response = await api.patch(endpoint.mark(data.id), { body: data })

			if (await requestValidation(response)) {
				commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'marks',
					data: response.data
				})
				notify.success('notify.transportMarkIsEdited')
			}

			return response.code
		},

		async removeTransportMark ({ commit }, id) {
			const response = await api.delete(endpoint.mark(id))

			if (await requestValidation(response)) {
				commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'marks',
					data: response.data
				})
				notify.success('notify.transportMarkIsRemoved')
			}

			return response.code
		}
	},
	getters: {}
}
