const apiVersion = 'api/v1'

export const auth = {
	totp: {
		create: ``,
		verify: ``,
		login: ``
	},
	u2f: {
		registration: {
			options: '',
			registration: ''
		},
		authorization: {
			options: `${apiVersion}`,
			authorization: `${apiVersion}`
		}
	},
	general: {
		login: `${apiVersion}/accounts/login/`
	},
	phone: {
		login: ''
	}
}

export const directoriesTransportCompany = [
	{
		endpoint: `${apiVersion}/directory/settlements/region/?page_size=5`,
		state: 'settlementsRegions'
	},
	{
		endpoint: `${apiVersion}/directory/settlements/settlement/?page_size=5`,
		state: 'settlements'
	},
	{
		endpoint: `${apiVersion}/directory/settlements/postal_code/?page_size=5`,
		state: 'postalCodes'
	}
]

export const directories = [
	{ endpoint: `${apiVersion}/directory/region/?page_size=5`, state: 'regions' },
	{
		endpoint: `${apiVersion}/accounts/user/?page_size=5`,
		state: 'responsible'
	},
	{ endpoint: `${apiVersion}/directory/dashboard_count/`, state: 'counters' },
	{ endpoint: `${apiVersion}/directory/marks/?page_size=5`, state: 'marks' },
	{ endpoint: `${apiVersion}/directory/model/?page_size=5`, state: 'models' },
	{
		endpoint: `${apiVersion}/directory/car_type/?page_size=5`,
		state: 'carTypes'
	},
	{
		endpoint: `${apiVersion}/directory/protocol_status/`,
		state: 'protocolStatuses'
	},
	{
		endpoint: `${apiVersion}/directory/department/`,
		state: 'departments'
	},
	{
		endpoint: `${apiVersion}/directory/location/?page_size=5`,
		state: 'locations'
	},
	...directoriesTransportCompany
]

export const profile = {
	me: `${apiVersion}/accounts/me/`,
	password: `${apiVersion}/accounts/password/`,
	createPassword: `${apiVersion}/accounts/create-password/`
}

export const users = {
	users: `${apiVersion}/accounts/user/`,
	user: id => `${apiVersion}/accounts/user/${id}/`,
	passwordRecover: `${apiVersion}/accounts/password-recover/`,
	resendPassword: id =>
		`${apiVersion}/accounts/user/${id}/resend_password_mail/`
}

export const devices = {
	devices: `${apiVersion}/devices/device/`,
	device: id => `${apiVersion}/devices/device/${id}/`,
	deviceHistory: id => `${apiVersion}/devices/device/${id}/history/`,
	deviceStats: id => `${apiVersion}/devices/device/${id}/stats/`
}

export const measurements = {
	measurements: `${apiVersion}/measurements/measurement/`,
	measurement: id => `${apiVersion}/measurements/measurement/${id}/`,
	measurementByNumber: number =>
		`${apiVersion}/measurements/measurement/last_result/${number}/`,
	downloadMeasurementPDF: id =>
		`${apiVersion}/measurements/measurement/${id}/pdf`,
	measurementHistory: id =>
		`${apiVersion}/measurements/measurement/${id}/history`
}

export const regions = {
	regions: `${apiVersion}/directory/region/`,
	region: id => `${apiVersion}/directory/region/${id}/`
}

export const locations = {
	locations: `${apiVersion}/directory/location/`,
	location: id => `${apiVersion}/directory/location/${id}/`
}

export const settlements = {
	regions: `${apiVersion}/directory/settlements/region/`,
	region: id => `${apiVersion}/directory/settlements/region/${id}/`,
	settlements: `${apiVersion}/directory/settlements/settlement/`,
	settlement: id => `${apiVersion}/directory/settlements/settlement/${id}/`,
	postalCodes: `${apiVersion}/directory/settlements/postal_code/`,
	postalCode: id => `${apiVersion}/directory/settlements/postal_code/${id}/`
}

export const transportCompanies = {
	transportCompanies: `${apiVersion}/transport_companies/`,
	transportCompany: id => `${apiVersion}/transport_companies/${id}/`
}

export const transportCatalog = {
	models: `${apiVersion}/directory/model/`,
	model: id => `${apiVersion}/directory/model/${id}/`,
	marks: `${apiVersion}/directory/marks/`,
	mark: id => `${apiVersion}/directory/marks/${id}/`,
	carTypes: `${apiVersion}/directory/car_type/`
}

export const protocols = {
	step: number => `${apiVersion}/protocols/step${number}/`,
	operator: `${apiVersion}/protocols/operator/`,
	layout: `${apiVersion}/directory/layout/`,
	driver: `${apiVersion}/protocols/driver/`,
	protocols: `${apiVersion}/protocols/protocol/`,
	protocolHistory: id => `${apiVersion}/protocols/protocol/${id}/history/`,
	redirectNotification: id =>
		`${apiVersion}/protocols/protocol/${id}/redirect_notification/`,
	cancel: id => `${apiVersion}/protocols/protocol/${id}/cancel/`,
	processNotification: id =>
		`${apiVersion}/protocols/protocol/${id}/process_notification/`,
	revise: id => `${apiVersion}/protocols/protocol/${id}/revise/`,
	signNotification: id =>
		`${apiVersion}/protocols/protocol/${id}/sign_notification/`,
	reviewProtocol: id =>
		`${apiVersion}/protocols/protocol/${id}/review_protocol/`,
	createResolution: id =>
		`${apiVersion}/protocols/protocol/${id}/create_resolution/`,
	signResolution: id =>
		`${apiVersion}/protocols/protocol/${id}/sign_resolution/`,
	cancelResolutionOnAppeal: id =>
		`${apiVersion}/protocols/protocol/${id}/cancel_resolution_on_appeal/`,
	payResolution: id =>
		`${apiVersion}/protocols/protocol/${id}/pay_resolution/`
}
