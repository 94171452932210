import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { users as endpoint } from '@/configs/endpoints'
import notify from '@ananasbear/notify'
const store = 'users'

export default {
	state: () => ({
		users: {},
		isLoad: false
	}),
	actions: {
		async getUsers (context, filter) {
			context.commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()
			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(`${endpoint.users}?${params}`)

			if (await requestValidation(response))
				context.commit('SET_DATA', {
					store,
					state: 'users',
					data: response.data
				})

			context.commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async createUser (context, data) {
			const response = await api.post(endpoint.users, { body: data })

			if (await requestValidation(response)) {
				context.commit('ADD_DATA_TO_RESULTS', {
					store,
					state: 'users',
					data: response.data
				})
				notify.success('notify.userCreated')
			}

			return response.code
		},

		async editUser (context, data) {
			const response = await api.put(`${endpoint.users}${data.id}/`, {
				body: data
			})

			if (await requestValidation(response)) {
				context.commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'users',
					data: response.data
				})
				notify.success('notify.userEdited')
			}

			return response.code
		},

		async removeUser (context, id) {
			const response = await api.delete(endpoint.user(id))

			if (await requestValidation(response)) {
				context.commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'users',
					data: response.data
				})
				notify.success('notify.userRemoved')
			}

			return response.code
		},

		async resetPassword (context, email) {
			const response = await api.post(endpoint.passwordRecover, { body: email })

			if (await requestValidation(response))
				notify.success('notify.instructionSentToEmail')

			return response.code
		},

		async resendPassword (context, id) {
			const response = await api.get(endpoint.resendPassword(id))

			if (await requestValidation(response))
				notify.success('notify.instructionSentToEmail')

			return response.code
		}
	}
}
