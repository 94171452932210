export default {
	notify: {
		success: 'Виконано успішно',
		warning: 'Зверніть увагу',
		error: 'Помилка виконання',
		info: 'Повідомлення',
		regionCreated: 'Регіональний центр успішно створений',
		regionEdited: 'Регіональний центр успішно відредаговано',
		regionRemoved: 'Регіональний центр успішно видалено',
		locationCreated: 'Локацію успішно створено',
		locationEdited: 'Локацію успішно відредаговано',
		locationRemoved: 'Локацію успішно видалено',
		userCreated: 'Користувач успішно створений',
		userEdited: 'Користувача успішно відредаговано',
		userRemoved: 'Користувача успішно видалено',
		profileEdited: 'Профіль успішно відредаговано',
		passwordWasChanged: 'Пароль успішно змінено',
		passwordWasCreated: 'Пароль успішно створений',
		instructionSentToEmail: 'Подальші інструкції відправлені на Email',
		deviceIsEdit: 'Пристрій успішно відредагованоі',
		deviceIsCreated: 'Пристрій успішно створено',
		transportCompanyIsCreated: 'Транспортну компанію успішно створено',
		transportCompanyIsEdited: 'Транспортну компанію успішно відредаговано',
		transportCompanyIsRemoved: 'Транспортну компанію успішно видалено',
		transportModelIsCreated: 'Модель успішно створено',
		transportModelIsEdited: 'Модель успішно відредаговано',
		transportModelIsRemoved: 'Модель успішно видалено',
		transportMarkIsCreated: 'Марку успішно створено',
		transportMarkIsEdited: 'Марку успішно відредаговано',
		transportMarkIsRemoved: 'Марку успішно видалено',
		registrationNumberIsEdit: 'Державний номер успішно відредаговано',
		operatorIsCreated: 'Оператора успішно створено',
		driverIsCreated: 'Водія успішно створено',
		reviewLocationWasChanged: 'Місце розгляду справи успішно змінено',
		departmentIsCreated: 'Департамент успішно створено',
		departmentIsEdited: 'Департамент успішно відредаговано',
		departmentIsRemoved: 'Департамент успішно видалено',
		validation: {
			required: "Поле обов'язкове", // vuelidate method name (email, minLength, ...)
			sameAs: 'Має бути таким же, як новий пароль', // eq - имя переменной с которой мы сравниваем
			invalidEmail: 'Невірний email',
			length: 'Довжина поля не відповдає допустимому',
			minLength: 'Довжина поля замала',
			maxLength: 'Довжина поля завелика',
			numeric: 'Тільки цифри',
			regexNumericComma: 'Тільки цифри',
			regexLetters: 'Тільки українська',
			minValue: 'Замале значення',
			maxValue: 'Завелике значення',
			greatEffort: 'Занадто велике зусилля, проведіть замір ще раз',
			regexLetterNumber: 'Тільки українські букви та цифри',
			letterNumber: 'Тільки цифри та крапка',
			email: 'Email не коректний, приклад example@domain.com',
			phone: 'Номер телефону не коректний',
			checkAvailable: "Поле обов'язкове до заповнення",
			codeEdrpouIpn: 'Код ЄДРПОУ/ІПН має містити 8 або 10 цифр ',
			countWheels: 'Кількість коліс повинна бути не менше кількості вісей',
			regexNumericDot: 'Тільки числа та крапка',
			invalidPassport: 'Недійсний паспорт',
			invalidPhoneFormat: 'Невірний номер телефону'
		},
		requestErrors: {
			region_with_this_value_already_exists: 'Регіональний центр із такою назвою вже існує',
			This_password_is_too_short_It_must_contain_at_least_8_characters:
				'Цей пароль занадто короткий. Він повинен містити не менше 8 символів',
			Incorrect_password: 'Поточний пароль невірний',
			This_password_is_too_common: 'Новий пароль часто поширений',
			Email_is_not_verified__contact_your_administrator_to_change_your_password:
				'Email неверифіковно',
			Incorrect_email_or_password: 'Невірний Email або пароль',
			Enter_a_valid_email_address:
				'Email не корректний або він не існує в системі',
			Phone_number_must_be_entered_in_the_format___999999999__Min_9_up_to_20_digits_is_allowed:
				'Телефон має бути у форматі +38 067 123 45 66',
			Can_not_deactivate_user_with_devices:
				'Неможливо деактивувати користувача з пристроями',
			err__Token_is_invalid_or_expired: 'Посилання недійсне або застаріло',
			A_user_with_this_email_address_already_exists:
				'Користувач із цією електронною адресою вже існує',
			A_user_already_exists:
				'Користувач вже існує',
			Can_not_deactivate_region_with_devices:
				'Неможливо деактивувати регіональний центр з пристроями',
			device_with_this_serial_number_already_exists:
				'Пристрій з таким S/N вже існує',
			User_with_this_email_does_not_exist:
				'Користувач з такою поштою відсутній',
			Ensure_this_filename_has_at_most_100_characters:
				'Назва завантаженого файлу не повина перевищувати 100 символів',
			Can_not_modify_disabled_Device:
				'Редагування деактивованого пристрою заборонено',
			Enter_a_number: 'Тільки цифрове значення',
			This_user_cant_be_deleted_because_it_has_got_attached_devices_history_records_or_measurements:
				"Видалити Співробітника не можливо! В історії є записи, пов'язані з цим користувачем",
			This_region_cant_be_deleted_because_it_has_got_attached_devices_or_measurements:
				"Видалити Регіональний центр не можливо! В історії є записи, пов'язані з цим регіональним центром",
			Транспортна_компанія_with_this_ЄДРПОУ_already_exists:
				'Транспортна компанія з таким ЄДРПОУ вже існує',
			Марка_КТЗ_with_this_name_already_exists: 'Марка з такою назвою вже існує',
			'Довідка_про_результати_габаритно-вагового_контролю_with_this_number_already_exists':
				'Документ з таким Номером довідкі вже існуе',
			'Довідка_про_результати_габаритно-вагового_контролю_with_this_act_of_inspection_number_already_exists':
				'Документ з таким Номером акту проведення перевірки вже існуе',
			'Довідка_про_результати_габаритно-вагового_контролю_with_this_act_of_excess_number_already_exists':
				'Документ з таким Номером акту про перевищення вагових параметрів вже існуе',
			Ensure_this_field_has_no_more_than_9_characters:
				'ЄДРПОУ повинен бути не більше 9 символів',
      Such_department_already_has_an_active_head: 'У такого Регіонального центру вже є активний Керівник',
      You_should_be_an_emplyee_of_some_department: 
        'Поточний користувач повинен бути співробітником Регіонального центру',
      Old_and_new_passwords_are_the_same: 'Старий і новий паролі однакові'  
		}
	}
}
