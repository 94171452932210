import Vue from 'vue'
import Vuex from 'vuex'

import mutations from '@/store/mutations'

import App from './modules/app'
import Directory from './modules/directory'

import Profile from './modules/profile'
import Users from './modules/users'
import Devices from './modules/devices'
import Measurements from './modules/measurement'
import Regions from './modules/regions'
import TransportCompanies from './modules/transportCompanies'
import TransportCatalog from './modules/transportCatalog'
import Protocols from './modules/protocols'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		app: App,
		directory: Directory,
		profile: Profile,
		users: Users,
		devices: Devices,
		measurements: Measurements,
		regions: Regions,
		transportCompanies: TransportCompanies,
		transportCatalog: TransportCatalog,
		protocols: Protocols
	},
	mutations
})

export default store
