import vuetify from './uk/vuetify'
import notify from './uk/notification'
import btn from './uk/btn'
import units from './uk/units'

export default {
	...vuetify,
	...notify,
	...btn,
	...units,
	error: {
		notfound: 'Сторінку не знайдено',
		other: 'Виявлено помилку'
	},
	login: {
		title: 'Увійти',
		email: 'Електронна адреса',
		password: 'Введіть пароль',
    rememberMe: 'Запам’ятати мене',
    registeredTransportCompany: 'Зареєструвати транспортну компанію ',
		button: 'Увійти',
		orsign: 'Або увійти за допомогою',
		forgot: 'Відновити пароль',
		noaccount: 'Не маєте облікового запису?',
		create: 'Створити тут',
		error: 'Введено неправильний логін / пароль',
		enterEmailPassword: 'Введіть email та пароль для входу або зареєструйтесь',
		resetPassword: 'Відновити пароль',
		enterEmailForInstruction:
			'Введіть свій Email, на нього будуть відправлені подальші інструкції.',
		createPassword: 'Створити пароль',
		createPasswordDescription:
			'Новий пароль буде використуватись для входу в систему',
    registerTransportCompany: 'Реєстрація транспортної компанії',
    enterRegistrationDetails: 'Введіть дані для реєстрації',
    fullNameTransportCompany: 'Повна назва транспортної компанії',
    agreement: `Я ознайомився і підтверджую правильність наданої інформації та даю згоду
      <a href="#" target="_blank">На обробку персональних даних</a>`
	},
	noDataText: 'Дані відсутні',
	personalInfo: 'Особиста інформація',
  regionalCenterInformation: 'Інформація про Регіональний центр',
	signBy2F: 'Введіть код двухфакторної авторизації',
	createBy2F: 'Відскануйте QR-код та введіть код двухфакторної авторизації',
	code: 'Код',
	// project: 'Проект',
	profile: {
		profile: 'Профіль',
		name: "Ім'я",
		key: 'Ключ U2F',
		security: 'Безпека',
		changePassword: 'Змінити пароль',
		changePasswordDescription:
			'Змініть пароль облікового запису',
		currentPassword: 'Поточний пароль',
		newPassword: 'Новий пароль',
		confirmPassword: 'Підтвердити пароль'
	},
	main: 'Головна',
	settings: 'Налаштування',
	authTOTP: 'Двухфакторна авторизація TOTP',
	authU2F: 'Двухфакторна авторизація U2F',
	notes: 'Примітки',
	note: 'Примітка',
	yes: 'Так',
	no: 'Ні',
	unit: 'Одиниця виміру',
	size: 'Розмір',
	action: 'Дія',
	actions: 'Дії',
	fullName: 'ПІБ',
	fullNameUser: 'ПІБ користувача',
	firstName: 'Ім’я',
	lastName: 'Прізвище',
	middleName: 'Побатькові',
	region: 'Регіон',
	regionalCenter: 'Регіональний центр',
	regionName: 'Назва регіонального центру',
	editRegion: 'Редагувати регіональний центр',
	editRegionDescription: 'Редагуйте інформацію про Регіональний центр',
	removeRegion: 'Ви дійсно бажаєте видалити Регіональний центр?',
	fullStateName: 'Повна назва органу з державного контролю',
  legalAddressState: 'Юридична адреса органу з державного контролю',
  actualAddressState: 'Фактична адреса органу з державного контролю',
	stateControlBody: 'Орган держ. контролю',
	confirmDeactivateRegion: 'Ви впевнені що хочете деактивувати Регіональний центр?',
	confirmActivateRegion: 'Ви впевнені що хочете активувати Регіональний центр?',
	companyName: 'Назва компанії',
	companyId: 'ID компанії',
	edrpou: 'ЄДРПОУ',
	codeEdrpouOrIpn: 'Код ЄДРПОУ/ІПН',
	head_name: 'ПІБ директора',
	legal_address: 'Юридична адреса',
	contactPhone: 'Контактний телефон',
	contactPhones: 'Контактні телефони',
	createAt: 'Дата створення',
	date: 'Дата',
	editDate: 'Дата ред.',
	edited: 'Відредагував',
	hour: 'Час',
	legalType: 'Тип',
	individual: 'Фіз. особа',
	menu: {
		main: 'Головна',
		measurements: 'Вимірювання',
		profile: 'Мій профіль',
		devices: 'Пристрої',
		logout: 'Вихід',
		workers: 'Працівники',
		regions: 'Регіональні центри',
		locations: 'Локації',
		transportCatalog: 'Каталог авто',
		transportCompanies: 'Транспортні компанії',
		protocols: 'Протоколи'
	},
	measurement: 'Вимірювання',
	listMeasurementsOfAuto: 'Список вимірів автомобіля',
	notHaveMeasurements: 'У цього автомобіля ще немає вимірів',
	status: 'Статус',
	editStatus: 'Змінити статус',
	statuses: {
		notActive: 'Деактивований',
		active: 'Активний',
		online: 'Увімкнений',
		offline: 'Вимкнений'
	},
	search: 'Пошук',
	searchByNumber: 'Пошук за номером авто',
	number: 'ID',
	noData: 'Немає даних',
	dataNotFound: 'Дані не знайдено',
	vehicle: {
		mark: 'Марка',
		model: 'Модель',
		markAndModel: 'Марка та модель',
		type: 'Тип КТЗ'
	},
	lastMeasurements: 'Останні вимірювання',
	measuringDevices: 'Вимірювальні прилади',
	total: 'Всього',
	active: 'Активні',
	disabled: 'Вимкнені',
	finnedIssue: 'Виписано штрафів',
	payed: 'Сплачених',
	totalMeasurement: 'Вимірювань всьго',
	measurementForWeek: 'Вимірювань за 7 днів',
	measurementToday: 'Вимірювань сьогодні',
	notProcessed: 'Не опрацьовані',
	weightMeasurement: 'Вимірювання ваги',
	loading: 'Завантаження...',
  positions: {
    head: 'Керівник',
    deputy: 'Заступник керівника',
    expert: 'Експерт'
  },
	phone: 'Телефон',
	phones: 'Телефони',
	mobileNumberDevice: 'Мобільний номер пристрою',
	deviceID: 'S/N пристрою',
	deviceData: 'Дані пристрою',
	simData: 'Дані SIM-карти',
	responsible: 'Відповідальний',
	numberCertificate: '№ сертифікату',
	dateAppointmentPerson: 'Дата призначення відповідального',
	dateOfEdit: 'Дата внесення змін',
	deactivate: 'Деактивація',
	idMeasurement: 'ID випробування',
	registrationNumberFilter: 'Державний номер авто',
	placeMeasurement: 'Місце проведення',
	onlyActive: 'Тільки активні',
	deviceName: 'Назва пристрою',
	name: 'Назва',
	onlyIncorrectData: 'Тільки некоректні дані',
	incorrectData: 'Некоректні дані',
	hasProtocol: 'Наявність протоколу',
	printResult: 'Друкувати результати',
	dateOfHolding: 'Дата проведення',
	testMode: 'Режим випробування',
	speedKMH: 'Швидкість км/г',
	shaft: 'Вісь',
	shaftMeasurement: 'Вісь {count}, кг',
	numberOfAxles: 'Кількість осей',
	totalWeight: 'Загальна вага, кг',
	device: 'Пристрій',
	addDevice: 'Додати пристрій',
	informationHistoryDevice: 'Інформація про зміни в пристрої',
	turnOff: 'Деактивувати',
	turnOn: 'Активувати',
	tests: 'Випробувань',
	deviceStatisticSN: 'Статистика пристрою S/N',
	quantityDate: 'кількість/дата',
	submitDeactivation: 'Підтвердження деактивації',
	submitActivation: 'Підтвердження активації',
	descriptionConfirmSN:
		'Для підтвердження Вам необхідно вказати S/N пристрою та натиснути кнопку Виконати',
	serialNumberDoesNotMatch: 'Серійний номер не співпадає з вибраним пристроєм',
	SNOrNameDevice: 'S/N або Назва пристрою',
	amountDevices: 'Пристрої (шт)',
	workerID: 'ID працівника',
	fullNameWorker: 'ПІБ працівника',
	position: 'Посада',
	editUser: 'Редагувати користувача',
	removeUser: 'Ви дійсно бажаєте видалити Співробітника?',
	editTransportCompany: 'Редагувати транспортну команію',
	removeTransportCompany: 'Ви дійсно бажаєте видалити Транспортну команію?',
	resetPasswordDescription: 'Подальші дії будуть відправлені на Email:',
	addModel: 'Додати модель',
	editModel: 'Редагувати модель',
	removeModel: 'Ви дійсно бажаєте видалити Модель?',
	marksCatalog: 'Каталог марок',
	addMark: 'Додати марку',
	editMark: 'Редагувати марку',
	removeMark: 'Ви дійсно бажаєте видалити Марку?',
	markName: 'Назва марки',
	vehicleMarkName: 'Введіть назву марки транспортного засобу',
	editVehicleMarkName: 'Редагуйте назву марки транспортного засобу',
	editRegistrationNumber: 'Редагувати державний номер',
	locationName: 'Назва локації',
	addLocation: 'Додати локацію',
	editLocation: 'Редагувати локацію',
	removeLocation: 'Ви дійсно бажаєте видалити Локацію?',
	descriptionRegistrationNumber:
		'В цьому вікні ви можете відредагувати державний номер, та переглянути історію зміни державного номера автомобіля',
	sameAs: {
		new_password: 'попереднє поле'
	},
	static: 'Статичний',
	dynamic: 'Динамічний',
	positionsCount: '{count} позицій',
	showingPositions: 'Відображено {count} з {all}',
	deviceEventLog: 'Реєстр подій пристрою',
	dateOfWeight: 'Дата зважування',
	confirmDeactivateUser: 'Ви впевнені що хочете деактивувати користувача?',
	confirmActivateUser: 'Ви впевнені що хочете активувати користувача?',
	ofYouRequestFind: 'За вашим запитом знайдено',
	records: 'записів',
	needAttention: 'Потребують уваги',
	logout: 'Вихід із системи',
	logoutDescription: 'Ви впевнені в тому що хочете вийти із системи?',
	cantDeactivateUserWithDevices:
		'Неможливо деактивувати користувача с наявними пристроями',
	resendApprove: 'Повторна відправка підтвердження',
	resendApproveDescription:
		'Буде згенероване нове посилання, та відправлено на Email',
	latitude: 'Широта',
	longitude: 'Довгота',
	coordinates: 'Координати',
	balance: 'Баланс',
	nextReplenishment: 'Наступне поповнення',
	availableMB: 'Кількість доступних Мб у пакеті, Мб',
	packagePrice: 'Вартість пакету на місяц (UAH)',
	nextPackageUpdate: 'Дата наступного оновлення пакетів',
	timeSIMUpdate: 'Дата та час оновлення даних SIM-картки пристрою',
	lowBalance: 'Низький баланс',
	lowBalanceFilter: 'Низький баланс SIM-карти',
	filtration: 'Фільтрація',
	lastMeasurement: 'Останній вимір',
	necessaryInformationTransportCompany:
		'Заповніть усю необхідну інформацію про транспортну компанію',
	amountLocations: 'Локації (шт)',
	locations: 'Локації',
	createProtocol: 'Створити протокол',
	protocol: 'Протокол',
	protocolHasNotYetBeenCreated: 'За даним виміруванням ще не створено протокол',
	createDocuments: 'Створення документів',
	fillNecessaryInformationDocuments:
		'Заповніть усю необхідну загальну інформацію про документи',
	protocolDecline: 'Ви дійсно бажаєте вийти?',
	protocolNotSave: 'Протокол не буде збережено',
	protocolConfirm: 'Ви дійсно бажаєте завершити створення протоколу?',
	protocolDocuments: 'Документи протоколу',
	addProtocol: {
		step1: {
			title: 'Створення документів',
			subtitle: 'Заповніть усю необхідну загальну інформацію про документи',
			weightControlCertificate:
				'Довідка про результати здійснення габаритно-вагового контролю',
			certificateNumber: 'Номер довідки',
			controlPlace: 'Місце проведення контролю',
			verifierFullName: 'ПІБ посадової особи, що проводила перевірку',
			verifierPosition: 'Посада посадової особи, що проводила перевірку',
			violationDetectedCreateAct:
				'Виявлено порушення (Створити акти порушення)?',
			actOfCarryingTransferPassengers: `Акт проведення перевірки додержання вимог законодавства про
      автомобільний транспорт під час здійснення перевезень пасажирів і вантажів автомобільним транспортом`,
			actNumber: 'Номер акта',
			roadListNumber: 'Номер дорожнього листа',
			referralForCheckingNumber: 'Номер направлення на перевірку',
			dateOfCreationReferral: 'Дата створення направлення',
			actOnExceedingWeightParameters:
				'Акт про про перевищення транспортним засобом нормативних вагових параметрів'
		},
		step2: {
			title: 'Внесення інформації про транспортний засіб',
			subtitle: `Заповніть усю необхідну інформацію про транспортний засіб,
        інформацію про габаритний котроль та про навантаження на осі і відстань між ними.`,
			vehicleMainInfo: 'Основна інформація про транспортний засіб',
			vehicleMark: 'Марка транспортного засобу',
			vehicleModel: 'Модель транспортного засобу',
			registrationNumber: 'Реєстраційний номер',
			certificateRegistrationSerialNumber:
				'Серія і номер свідоцтва про реєстрацію',
			trailerInformation: 'Інформація про причіп',
			trailerMark: 'Марка (причіп)',
			trailerModel: 'Модель (причіп)',
			trailerRegistrationNumber: 'Реєстраційний номер (причіп)',
			vehicleDimensions: 'Габарити транспортного засобу',
			inSize: 'В габариті',
			dimensions: {
				0: 'Довжина (м)',
				1: 'Ширина (м)',
				2: 'Висота (м)'
			},
			grossWeightT: 'Повна маса (т)',
			permissibleMassT: 'Допустима маса (т)',
			factualMassT: 'Фактична маса (т)',
			vehicleSchematicLayout: 'Схематичне компонування транспортного засобу',
			createNew: 'Створити нову',
			distanceInformationAxesM: 'Інформація про відстань між осями (м).',
			distanceBetweenAxesM: 'Відстань від {first} до {second} осі (м)',
			dragAndDropFiles:
				'Перетягніть у це поле файли, або натисніть для завантаження',
			drawSchematicVehicle:
				'Намалюйте схематичне зображення транспортного засобу тут'
		},
		step3: {
			title: 'Внесення інформації про транспортний засіб',
			subtitle: `Заповніть усю необхідну інформацію про транспортний засіб,
        інформацію про габаритний котроль та про навантаження на осі і відстань між ними.`,
			axesGroupsInformation: 'Інформація про групи осей',
			axisGroup: 'Група осей {count}',
			amountAxesInGroup: 'Кількість осей в групі',
			availableGroupWeight: 'Допустиме навантаження на группу (т)',
			factualAxisWeight: 'Фактичне навантаження на вісь {count} (т)',
			group: 'Група'
		},
		step4: {
			title: 'Інформація про транспортну компанію та водія',
			subtitle: 'Заповніть усю необхідну інформацію',
			driverInformation: 'Інформація про водія',
			driverFullName: 'ПІБ водія',
			dateOfBirth: 'Дата народження',
			addressOfResidence: 'Адреса проживання',
			phoneNumber: 'Номер телефону',
			identityDocument: 'Документ, що посвідчує особу',
			documentSeriesNumber: 'Серія та номер документу',
			documentDateOfIssue: 'Дата видачі документу',
			companyInformation: 'Інформація про компанію'
		},
		step5: {
			title: 'Інформація про порушення, вантаж та маршрут',
			subtitle:
				'Заповніть усю необхідну інформацію про порушення, вантаж та маршрут руху',
			cargoAndRoutInformation: 'Інформація про вантаж та маршрут руху',
			cargoInformationWithDangerous:
				'Найменування та вид вантажу, в тому числі небезпечного',
			trafficRoute: 'Маршрут руху із зазначенням пройденої відстані',
			violationDescriptionAndExplanation: 'Опис порушення та пояснення',
			violationDescription: 'Опис порушення',
			violationDescriptionLaw:
				'Опис порушення, відповідальність за які передбачена статтею 60 Закону України "Про автомобільний транспорт"',
			driverExplanation: 'Пояснення водія про причини порушень'
		},
		step6: {
			title: 'Перегляд документів',
			subtitle: 'Уважно перегляньте згенеровані документи',
			agreement: `Я ознайомився і підтверджую правильність наданої інформації та даю згоду
        <a href="#" target="_blank">На обробку персональних даних</a>`
		},
		step7: {
			title: 'Підпис документу',
			subtitle: 'Заповніть усю необхідну інформацію про порушення',
			driverSignature: 'Підпис водія',
			enterDriverSignature: 'В даному вікні введіть підпис водія'
		},
		step8: {
			title: 'Підпис документу',
			subtitle: 'Заповніть усю необхідну інформацію про порушення',
			responsibleSignature: 'Підпис відповідального',
			enterResponsibleSignature: 'В даному вікні введіть підпис відповідального'
		},
		step9: {
			title: 'Підпис документу',
			subtitle: 'Заповніть усю необхідну інформацію про порушення',
			operatorSignature: 'Підпис оператора',
			enterOperatorSignature: 'В даному вікні введіть підпис оператора'
		}
	},
	certificate: 'Довідка про результати здійснення габаритно-вагового контролю',
	act_of_inspection: `Акт проведення перевірки додержання вимог законодавства про автомобільний транспорт
    під час здійснення перевезень пасажирів і вантажів автомобільним транспортом`,
	act_of_excess:
		'Акт про про перевищення транспортним засобом нормативних вагових параметрів',
	fieldIsRequired: `Поле обов'язкове`,
	passport: 'Паспорт',
	drivingLicense: 'Посвідчення водія',
	internationalPassport: 'Закордонний паспорт',
	measurementDate: 'Дата та час випробування',
	mainInformation: 'Основна інформація',
	cargoOperator: 'Оператор вагового комплексу',
	controlPointLocation: 'Місце розташування пункту контролю',
	responsibleUkrTransSecurity: 'Посадова особа Укртрансбезпеки',
	stateName: 'Назва органу державного контролю',
	vehicleSchematic: 'Схематичне зображення ТЗ',
	vehicleTruck: 'Автомобіль (тягач)',
	trailerSemiTrailer: 'Причіп (напівпричіп)',
	overallInspectionVehicleResults: 'Результати габаритного контролю ТС',
	weightControlVehicleResults: 'Результати вагового контролю ТС',
	vehicleFullWeight: 'Повна маса ТЗ (т)',
	axisWeight: 'Навантаження на вісь {count} (т)',
	referralInspection: 'Направлення на перевірку від',
	vehicleLabel: 'Транспортний засіб',
	driver: 'Водій',
	identityDocumentName: 'Назва документу що посвідчує особу',
	whoIssuedDocument: 'Ким виданий документ',
	transportCompany: 'Транспортна компанія',
	violation: 'Порушення',
	violationDetected: 'Виявлено порушення',
	violationDetectedLaw:
		'у тому числі порушення, відповідальність за які передбачена статтею 60 Закону України “Про автомобільний транспорт”',
	weighingDetailedDescription: 'Докладний опис зважування',
	cargoInformation: 'Найменування та вид вантажу',
	trafficRouteKM: 'Маршрут руху та відстань (км)',
	normativeAvailableWeight: 'Нормативно допустима маса (т)',
	totalAxesAmount: 'Загальна кількість осей',
	normativeAxesWeight: 'Осьові навантаження, нормативні (т)',
	axesWeight: 'Осьові навантаження, фактичні (т)',
	distanceBetweenAxes: 'Відстань між осями (м)',
	measurementDateProtocol: 'Дата вимірювання',
	measurementRegion: 'Регіон вимірювання',
	processingRegion: 'Регіон обробки',
	companyEdrpou: 'Компанія (ЕДРПОУ)',
	driverFullName: 'ПІБ водія',
	reviewDate: 'Дата розгляду',
	documentType: 'Тип документу',
	certificateNumber: 'Номер довідки',
	companyNameEdrpou: 'Назва компанії або ЄДРПОУ',
	certificateOrActsNumber: '№ довідки або актів',
	noticeOrResolutionNumber: '№ Повідомлення або Постанови',
	incidentRegion: 'Регіон проведення',
	reviewRegion: 'Регіон розгляду',
	measurementResponsible: 'Відповідальний за вимірювання',
	accountNumber: 'Номер счета',
	driverFullNameOrDocumentNumber: 'ПІБ Водія або № документу',
	vehicleNumberOrRegistrationNumber: 'Номер авто або номер реєстрації',
	idProtocol: 'ID протоколу',
	protocolMainInformation: 'Основна інформація по Протоколу',
	protocolStatus: 'Статус протоколу',
	reviewRegionCase: 'Регіон розгляду справи',
	dateReview: 'Дата та час розгляду',
	actNumber: 'Номер акту {number}',
	notificationNumber: 'Номер повідомлення',
	resolutionNumber: 'Номер постанови',
	vehicleMarkAndModel: 'Марка та модель транспортного засобу',
	registrationNumber: 'Реєстраційний номер',
	idDriver: 'ID водія',
	residence_address: 'Адреса проживання',
	birth_date: 'Дата народження',
	identity_type: 'Документ',
	identity_number: 'Серія документу',
	issue_date: 'Дата видачі',
	from: 'від',
	comments: 'Коментарі',
	writeYourComment: 'Напишіть свій коментар',
	history: 'Історія',
	reviewer: 'Розглядаючий',
	redirectReview: 'Перенаправити на розгляд',
	changeReviewLocation: 'Змінити місце розгляду справи?',
	chooseRegionReview: 'Оберіть регіон розгляду справи',
	locationReviewProcess: 'Місце проведення розгляду справи',
	cancelViolation: 'Скасувати порушення',
	reviewViolation: 'Розглянути порушення',
	specifyCancellationReason: 'Вкажіть причину скасування',
	sendForSignature: 'Відправити на підпис',
	creatingNotification: 'Створення Повідомлення',
	new: 'Нові',
	performerName: 'ПІБ виконавця',
	performerPhone: 'Телефон виконавця',
	headName: 'ПІБ Начальника',
	viewNotification: 'Перегляд Повідомлення',
	sendForRevision: 'Відправити на доопрацювання',
	confirmAction: 'Підтвердити виконання дії',
	sendNotificationForSignature: 'Бажаєте відправити Повідомленн на підпис?',
	cancelEditionNotification: 'Бажаєте скасувати редагування Повідомлення?',
	signatureNotification: 'Бажаєте підписати Повідомлення?',
	sendForRevise: 'Бажаєте відправити на доопрацювання?',
	notifyByEmail: 'Сповістити на email',
	sendForReview: 'Відправити на розгляд справи',
	wouldSendForReview: 'Бажаєте відправити на розгляд?',
	wouldNotifyByEmail: 'Бажаєте сповістити на email?',
	makeResolution: 'Винести рішення',
	wouldMakeResolution: 'Бажаєте винести рішення?',
	creationResolution: 'Створення Постанови',
	onReview: 'На розгляді',
	saveResolution: 'Зберегти постанову',
	wouldSaveResolution: 'Бажаєте зберегти Постанову?',
	cancelCreationResolution: 'Бажаєте скасуати редагування Постанови?',
	transporterName: 'Наименование автомобильного перевозчика',
	transporterLocation: 'Місцезнаходження перевізника',
	bankDetails: 'Банківськи реквізіти',
	reviewDateTimeLocation: 'Дата, час та місце перевірки',
	violationAllowed: 'Допущено порушення',
	responsibilityForProvide: 'Відповідальність за яке передбачена',
	actConfirmed: 'Підтверджується актом',
	fineAmount: 'Сума штрафу',
	executionDeadline: 'Строк до виконання',
	paymentDetails: 'Реквізити до сплати',
	viewResolution: 'Перегляд Постанови',
	departments: 'Департаменти',
	department: 'Департамент',
	departmentName: 'Назва департаменту',
	addDepartment: 'Додати департамент',
	editDepartment: 'Редагувати департамент',
	removeDepartment: 'Ви дійсно бажаєте видалити Департамент?',
	requisites: 'Реквізити',
	notificationHeader: 'Шапка Повідомлення',
	resolutionHeader: 'Шапка Постанова',
	settlementRegion: 'Область',
	city: 'Місто',
	index: 'Індекс',
	stateRegistrationCertificate: 'Свідоцтво про державну реєстрацію',
	license: 'Ліцензія',
	notification: 'Повідомлення',
	signResolution: 'Підписати постанову',
	signatureResolution: 'Бажаєте підписати Постанову?',
	cancelOnAppeal: 'Скасувати за оскарженням',
	creditPayment: 'Зарахувати оплату',
	wouldCreditPayment: 'Бажаєте зарахувати оплату?',
	resolution: 'Постанова',
	paymentAccount: 'Счет на оплату',
  profileEmail: 'Email/логін',
  companyInformation: 'Інформація про компанію',
  actualAddress: 'Фактична адреса',
	headTransportCompany: 'Голова транспортної компанії'
}

// І і ї Є є '
