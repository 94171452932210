import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { regions, locations } from '@/configs/endpoints'
import notify from '@ananasbear/notify'
const store = 'regions'

export default {
	state: () => ({
		regions: {},
		region: {},
		locations: {},
		isLoad: false
	}),
	actions: {
		async getRegions ({ commit }, filter) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()
			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(`${regions.regions}?${params}`)

			if (await requestValidation(response))
				commit('SET_DATA', {
					store,
					state: 'regions',
					data: response.data
				})

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getRegion ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(regions.region(id))

			if (await requestValidation(response))
				commit('SET_DATA', {
					store,
					state: 'region',
					data: response.data
				})

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async createRegion ({ commit }, data) {
			const response = await api.post(regions.regions, { body: data })

			if (await requestValidation(response)) {
				commit('ADD_DATA_TO_RESULTS', {
					store,
					state: 'regions',
					data: response.data
				})
				notify.success('notify.regionCreated')
				return response.data
			}

			return false
		},

		async editRegion ({ commit }, data) {
			const response = await api.put(regions.region(data.id), { body: data })

			if (await requestValidation(response)) {
				commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'regions',
					data: response.data
				})
				notify.success('notify.regionEdited')
				return response.data
			}

			return false
		},

		async editProfileRegion ({ commit }, data) {
			const response = await api.put(regions.region(data.id), { body: data })

			if (await requestValidation(response)) {
				commit('SET_DATA', {
					store,
					state: 'region',
					data: response.data
				})
				notify.success('notify.regionEdited')
				return response.data
			}

			return false
		},

		async removeRegion ({ commit }, id) {
			const response = await api.delete(regions.region(id))

			if (await requestValidation(response)) {
				commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'regions',
					data: response.data
				})
				notify.success('notify.regionRemoved')
			}

			return response.code
		},

		async getLocations ({ commit }, filter) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()
			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(`${locations.locations}?${params}`)

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'locations', data: response.data })

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async createLocation ({ commit }, data) {
			const response = await api.post(locations.locations, { body: data })

			if (await requestValidation(response)) {
				commit('ADD_DATA_TO_RESULTS', {
					store,
					state: 'locations',
					data: response.data
				})
				notify.success('notify.locationCreated')
				return response.data
			}

			return false
		},

		async editLocation ({ commit }, data) {
			const response = await api.put(locations.location(data.id), {
				body: data
			})

			if (await requestValidation(response)) {
				commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'locations',
					data: response.data
				})
				notify.success('notify.locationEdited')
				return response.data
			}

			return false
		},

		async removeLocation ({ commit }, id) {
			const response = await api.delete(locations.location(id))

			if (await requestValidation(response)) {
				commit('UPDATE_DATA_TO_RESULTS', {
					store,
					state: 'locations',
					data: response.data
				})
				notify.success('notify.locationRemoved')
			}

			return response.code
		}
	},
	getters: {}
}
