import { Authorization } from '@ananasbear/auth'
import { auth } from '@/configs/endpoints'

const options = {
	tokenKey: 'Token',
	urls: auth
}

const authorization = new Authorization(options)

export default authorization
