import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import router from '@/router'

import authorization from './plugins/auth'
import api from './plugins/api'
import { access } from './configs/permissions'

import notify from '@ananasbear/notify'
import validate from '@ananasbear/validation'
import i18n from '@/plugins/vue-i18n'
import Table from '@/components/Layouts/Table'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueSignaturePad from 'vue-signature-pad'
import moment from 'moment-timezone'

Vue.component('Table', Table)
Vue.use(VueSignaturePad)
Vue.use(Vuelidate)
Vue.use(VueMask)

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@/assets/scss/material-dashboard.scss'

Vue.config.productionTip = false

Vue.prototype.$notification = notify
Vue.prototype.$api = api
Vue.prototype.$auth = authorization
Vue.prototype.$valid = validate
Vue.prototype.$moment = moment
Vue.prototype.$access = access

Vue.mixin({
	methods: {
		$isEval(param) {
			param = param.replaceAll('.', '?.')
			const element = eval('this.' + param)
			if (element || element === 0) return element
			else return ''
		}
	}
})

new Vue({
	store,
	router,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app')
