import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { measurements as endpoint } from '@/configs/endpoints'
import notify from '@ananasbear/notify'
const store = 'measurements'

export default {
	state: () => ({
		measurements: {},
		measurement: {},
		isLoad: false,
		measurementsByNumber: {},
		measurementHistory: {}
	}),
	actions: {
		async getMeasurements (context, filter) {
			context.commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()

			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(`${endpoint.measurements}?${params}`)

			if (await requestValidation(response))
				context.commit('SET_DATA', {
					store,
					state: 'measurements',
					data: response.data
				})

			context.commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getMeasurement (context, id) {
			context.commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(endpoint.measurement(id))

			if (await requestValidation(response))
				context.commit('SET_DATA', {
					store,
					state: 'measurement',
					data: response.data
				})

			context.commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getMeasurementByNumber ({ commit }, number) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(endpoint.measurementByNumber(number))

			if (await requestValidation(response))
				commit('SET_DATA', {
					store,
					state: 'measurementsByNumber',
					data: response.data
				})

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getMeasurementHistory ({ commit }, data) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()

			for (const [key, value] of Object.entries(data.filter))
				value && params.append(key, value)

			const response = await api.get(
				`${endpoint.measurementHistory(data.id)}?${params || ''}`
			)

			if (await requestValidation(response))
				commit('SET_DATA', {
					store,
					state: 'measurementHistory',
					data: response.data
				})

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async editMeasurement ({ commit }, data) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(endpoint.measurement(data.id), {
				body: data.body
			})

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'measurement', data: response.data })
				notify.success('notify.registrationNumberIsEdit')
			}

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		}
	},
	getters: {}
}
