import en from '@/configs/translations/en'
import uk from '@/configs/translations/uk'

let locale = 'uk'

export default {
	locale,
	fallbackLocale: 'uk',
	availableLocales: [
		{
			code: 'en',
			flag: 'us',
			label: 'English',
			messages: en
		},
		{
			code: 'uk',
			flag: 'ua',
			label: 'Ukrainian',
			messages: uk
		}
	]
}
