import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { protocols } from '@/configs/endpoints'
import notify from '@ananasbear/notify'
const store = 'protocols'

export default {
	state: () => ({
		steps: [],
		measurementProtocol: {},
		protocols: {},
		protocol: {},
		history: {},
		operators: {},
		drivers: {},
		layouts: {},
		isLoad: false
	}),
	actions: {
		async getMeasurementProtocol ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(`${protocols.step(6)}${id}/`)

			if (await requestValidation(response))
				commit('SET_DATA', {
					store,
					state: 'measurementProtocol',
					data: response.data
				})

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getProtocol ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(`${protocols.protocols}${id}/`)

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getProtocolHistory ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(protocols.protocolHistory(id))

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'history', data: response.data })

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async changeProtocol ({ commit }, { id, data }) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(`${protocols.protocols}${id}/`, {
				body: data
			})

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getProtocols ({ commit }, filter) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()
			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(`${protocols.protocols}?${params}`)

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'protocols', data: response.data })

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getStep ({ commit }, { number, id }) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(`${protocols.step(number)}${id}/`)

			if (await requestValidation(response))
				commit('SET_DATA_BY_INDEX', {
					store,
					state: 'steps',
					index: number - 1,
					data: response.data
				})

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async completeStep ({ commit, state }, { number, data }) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			let response
			!state.steps.length
				? (response = await api.post(protocols.step(1), { body: data }))
				: (response = await api.put(
						`${protocols.step(number > 7 ? 7 : number)}${state.steps[0].id}/`,
						{ body: data }
				  ))

			if (await requestValidation(response)) {
				if (state.steps?.[number - 1])
					commit('SET_DATA_BY_INDEX', {
						store,
						state: 'steps',
						index: number - 1,
						data: response.data
					})
				else commit('ADD_DATA', { store, state: 'steps', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.data
			}

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return false
		},

		async deleteProtocol ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.delete(`${protocols.step(1)}${id}/`)

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'steps', data: [] })
				commit('SET_DATA', { store, state: 'isLoad', data: false })
			}

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async getOperators ({ commit }, filter) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()
			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(`${protocols.operator}?${params}`)

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'operators', data: response.data })

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async createOperator ({ commit }, data) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.post(protocols.operator, { body: data })

			if (await requestValidation(response)) {
				commit('ADD_DATA_TO_RESULTS', {
					store,
					state: 'operators',
					data: response.data
				})
				notify.success('notify.operatorIsCreated')

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.data
			}

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return false
		},

		async getLayouts ({ commit }) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(protocols.layout)

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'layouts', data: response.data })

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async createLayout ({ commit }, file) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const body = new FormData()
			body.append('file', file)
			const response = await api.post(protocols.layout, { body: body })

			if (await requestValidation(response)) {
				commit('ADD_DATA_TO_RESULTS', {
					store,
					state: 'layouts',
					data: response.data
				})

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.data
			}

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return false
		},

		async getDrivers ({ commit }, filter) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const params = new URLSearchParams()
			for (const [key, value] of Object.entries(filter))
				value && params.append(key, value)

			const response = await api.get(`${protocols.driver}?${params}`)

			if (await requestValidation(response))
				commit('SET_DATA', { store, state: 'drivers', data: response.data })

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return response.code
		},

		async createDriver ({ commit }, data) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.post(protocols.driver, { body: data })

			if (await requestValidation(response)) {
				commit('ADD_DATA_TO_RESULTS', {
					store,
					state: 'drivers',
					data: response.data
				})
				notify.success('notify.driverIsCreated')

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.data
			}

			commit('SET_DATA', { store, state: 'isLoad', data: false })

			return false
		},

		async redirectProtocolNotification ({ commit }, { id, data }) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(protocols.redirectNotification(id), {
				body: data
			})

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })
				notify.success('notify.reviewLocationWasChanged')

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		},

		async cancelProtocol ({ commit }, { id, data }) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(protocols.cancel(id), { body: data })

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		},

		async processProtocolNotification ({ commit }, data) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(protocols.processNotification(data.id), {
				body: data.body
			})

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		},

		async reviseProtocol ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(protocols.revise(id))

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		},

		async signProtocolNotification ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(protocols.signNotification(id))

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		},

		async reviewProtocol ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.get(protocols.reviewProtocol(id))

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		},

		async createProtocolResolution ({ commit }, data) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(protocols.createResolution(data.id), {
				body: data.body
			})

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		},

		async signProtocolResolution ({ commit }, id) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(protocols.signResolution(id))

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		},

		async cancelResolutionOnAppeal ({ commit }, { id, data }) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(protocols.cancelResolutionOnAppeal(id), {
				body: data
			})

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		},

		async payResolution ({ commit }, { id, data }) {
			commit('SET_DATA', { store, state: 'isLoad', data: true })

			const response = await api.patch(protocols.payResolution(id), {
				body: data
			})

			if (await requestValidation(response)) {
				commit('SET_DATA', { store, state: 'protocol', data: response.data })

				commit('SET_DATA', { store, state: 'isLoad', data: false })

				return response.code
			}

			return false
		}
	},
	getters: {}
}
