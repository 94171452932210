import Vue from 'vue'

const SET_DATA = (context, data) => {
	context[data.store][data.state] = data.data
}

const ADD_DATA = (context, data) => {
	context[data.store][data.state].push(data.data)
}

const ADD_DATA_WITH_AVAILABILITY = (context, data) => {
	data.data.results.forEach(item => {
		const isFind = context[data.store][data.state].results.some(
			e => e.id === item.id
		)
		!isFind && context[data.store][data.state].results.push(item)
	})
}

const ADD_DATA_TO_RESULTS = (context, data) => {
	context[data.store][data.state].results.unshift(data.data)
}

const UPDATE_DATA_TO_RESULTS = (context, data) => {
	context[data.store][data.state].results.forEach((item, index) => {
		if (item.id === data.data.id)
			context[data.store][data.state].results.splice(index, 1, data.data)
	})
}

const SET_DATA_BY_INDEX = (context, data) => {
	context[data.store][data.state][data.index] = data.data
}

const DELETE_DATA = (context, data) => {
	context[data.store][data.state] = context[data.store][data.state].filter(
		i => i.id !== data.data
	)
}

const DELETE_DATA_TOKEN = (context, data) => {
	context[data.store][data.state].results = context[data.store][
		data.state
	].results.filter(i => i.pk_md5 !== data.data)
}

const ADD_PASSPORT_TO_CLIENTS = (context, data) => {
	let client = context[data.store][data.state].find(
		i => i.id === data.data.id
	)

	Vue.set(client, 'passport', data.data.passport)
}

export default {
	SET_DATA,
	DELETE_DATA,
	SET_DATA_BY_INDEX,
	ADD_DATA,
	ADD_PASSPORT_TO_CLIENTS,
	DELETE_DATA_TOKEN,
	ADD_DATA_TO_RESULTS,
	UPDATE_DATA_TO_RESULTS,
	ADD_DATA_WITH_AVAILABILITY
}
