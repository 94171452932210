import notify from '@ananasbear/notify'

export default async response => {
	switch (response.code) {
		case 200:
		case 201:
		case 204:
			return true
		case 400:
    case 401:
    case 403: {
			const body = await response.data.json()

			for (const error of Object.entries(body)) {
				if (error[1][0].includes('(it has'))
					error[1][0] = error[1][0].split(' (it has')[0]

				notify.warning(
					`notify.requestErrors.${error[1][0]
						.replace(/ |,|:|;|"/g, '_')
						.replaceAll('.', '')
						.replaceAll('(', '')
						.replaceAll(')', '')}`
				)
			}

			return false
		}
		case 422:
			{
				const body = await response.data.json()

				notify.warning(
					`notify.requestErrors.${body
						.replaceAll(/[\s,:;"]+/g, '_')
						.replaceAll(/[()\.`]+/g, '')}`
				)
			}
			break
		default:
			return false
	}
}
