export default {
	state: () => ({
		isMini: true,
		isLoad: true
	}),
	mutations: {
		SET_LOAD(state, data) {
			state.isLoad = data
		}
	},
	actions: {},
	getters: {}
}
