import store from '@/store'

let role = []
let position = []

export const initPermissions = () => {
	const profile = store.state.profile.profile
	;(role = {
		centralDepartment: profile.type_user === 'Central department',
		regionalDepartment: profile.type_user === 'Regional department',
		TransportCompany: profile.type_user === 'Transport company'
	}),
		(position = {
			head: profile.position === 'Начальник',
			deputy: profile.position === 'Виконуючий обовʼязки начальника',
			expert: profile.position === 'Експерт'
		})
}

export const access = typeAccess => {
	if (typeAccess === 'public') {
		return true
	}

	switch (typeAccess) {
		// Dashboard
		case 'dashboardView':
			return role.centralDepartment || role.regionalDepartment

		// Measurements
		case 'measurementsView':
			return role.centralDepartment || role.regionalDepartment

		// Protocols
		case 'canEditProtocol':
			return role.centralDepartment || role.regionalDepartment

		// Users
		case 'usersView':
			return (
				(role.centralDepartment || role.regionalDepartment) &&
				(position.head || position.deputy)
			)

		// Devices
    case 'devicesView':
			return role.centralDepartment || role.regionalDepartment
		case 'canCreateDevice':
			return (
				(role.centralDepartment || role.regionalDepartment) &&
				(position.head || position.deputy)
			)
		case 'canEditDevice':
			return (
				(role.centralDepartment || role.regionalDepartment) &&
				(position.head || position.deputy)
			)
		case 'canRemoveDevice':
			return (
				(role.centralDepartment || role.regionalDepartment) &&
				(position.head || position.deputy)
			)

		// Regions
		case 'canViewRegion':
			return role.centralDepartment && (position.head || position.deputy)
      
		// Locations
		case 'canViewLocation':
			return (
				(role.centralDepartment || role.regionalDepartment) &&
				(position.head || position.deputy)
			)

		// Transport catalog
		case 'canViewTransportCatalog':
			return role.centralDepartment || role.regionalDepartment

		// Transport companies
		case 'canRemoveTransportСompany':
			return role.centralDepartment && (position.head || position.deputy)

    // Profile
		case 'canEditRegion':
			return role.centralDepartment && (position.head || position.deputy)  

		default:
			return false
	}

	// if (typeof typeAccess === 'object') {
	//   return typeAccess.some(p => profile.permissions.includes(p))
	// }
	// else return profile.permissions.includes(typeAccess)
}
