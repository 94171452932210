import { profile } from '@/configs/endpoints'
import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import notify from '@ananasbear/notify'
import router from '@/router'

import { initPermissions } from '@/configs/permissions'

const store = 'profile'

export default {
	state: () => ({
		profile: {},
		sessions: {}
	}),
	actions: {
		async getProfile (context) {
			const response = await api.get(profile.me)
			if (await requestValidation(response)) {
				context.commit('SET_DATA', {
					store,
					state: 'profile',
					data: response.data
				})
				initPermissions()
				return response.data
			}
		},

		async editProfile (context, data) {
			const response = await api.patch(profile.me, { body: data })

			if (await requestValidation(response)) {
				context.commit('SET_DATA', {
					store,
					state: 'profile',
					data: response.data
				})
				notify.success('notify.profileEdited')
			}

			return response.code
		},

		async changePassword (context, data) {
			const response = await api.post(profile.password, { body: data })

			if (await requestValidation(response))
				notify.success('notify.passwordWasChanged')

			return response.code
		},

		async createPassword (context, data) {
			const response = await api.post(
				`${profile.createPassword}${window.location.search}`,
				{ body: data }
			)

			if (await requestValidation(response)) {
				notify.success('notify.passwordWasCreated')
				router.push('/login')
			}

			return response.code
		}
	}
}
